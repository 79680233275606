import { Logo as LogoIcon, Subtitle3 } from '@opinary/ui-components'
import styled, { css } from 'styled-components'
export const PageContainer = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.gray[1]};
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 80px auto;
  `
)

export const Subtitle = styled(Subtitle3)`
  max-width: 450px;
  margin: 20px auto;
  text-align: center;
`

export const Info = styled(Subtitle3)(
  ({ theme }) => css`
    color: ${theme.colors.gray[2]};
  `
)

export const Logo = styled(LogoIcon)(
  ({ theme: { colors } }) => css`
    width: 108px;
    margin-bottom: 48px;
    fill: ${colors.gray[0]};
  `
)
